import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {Button, ModalCloseButton} from 'components/common/Buttons';
import classNames from 'classnames/bind';

import styles from './style.module.scss';
import './chooseColorStyle.scss'
import {ScreenContentLayout, Spinner} from "../../common";
import ContentView from "../../../screens/Configurator/ChooseColor/ContentView";
import {
    useGetConfiguratorColorQuery,
    useGetDiscListQuery,
    useGetStyledCarImagesMutation
} from "../../../store/configuratorCars/api";
import {useLocation, useParams} from "react-router-dom";
import {NewCarModelShort} from "../../../store/newCars/types";
import {useSelector} from "react-redux";
import {selectedDealer} from "@store/dealers/slice";
import {translationsDataArr} from "@store/translate/slice";
import getTranslateText from "@utils/getTranslateText";
const cx = classNames.bind(styles);

type IShowConfiguratorCar = {
    closeModal: () => void;
    modeficationsId?:string;
    carId?:string;
};

const ShowConfiguratorCarModal = ({closeModal,modeficationsId,carId}: IShowConfiguratorCar) => {
    const params = useParams();
    const dealer = useSelector(selectedDealer);
    const location = useLocation();
    const state= location.state as {
        car: NewCarModelShort & { vehicleCompletion_id: string };
        modefications:string;
    };
    const translateArr = useSelector(translationsDataArr);
    const { modefications,car } = state;
    const { data: carDetailResponse, isLoading: isLoadingCarDetail,refetch } =
        useGetConfiguratorColorQuery({
            dealer_dealer_code: dealer || 'AZ',
            vehicleModel_id:carId|| car.id,
            vehicleModification_id: modeficationsId||modefications,
        });
    const { data: discList, isLoading: isLoadingDiscList } =
        useGetDiscListQuery({
            dealer_dealer_code: dealer || 'AZ',
            vehicleModel_id:carId|| car.id,
            vehicleModification_id: modeficationsId||modefications,
        });
    const discDevider = discList?.data?.accessory_discs.length || 0
    const discListArray = [...(discList?.data?.accessory_discs || []), ...(discList?.data?.standard_discs || [])]
    const isLoading = React.useMemo(
        () => isLoadingCarDetail,
        [isLoadingCarDetail],
    );
    return (
        <Box className={cx('modal')}>
            <ModalCloseButton onClick={() => closeModal()} />
            <div className={cx('modal__content')}>
                <div className="choose-color modal-choose-color">
                {isLoading || isLoadingDiscList || !carDetailResponse?.data ||!discList?.data ? (
                    <ScreenContentLayout classes="content">
                        <Spinner />
                    </ScreenContentLayout>
                ) :
                <>
                    <p className={cx('title')}>{getTranslateText(translateArr, 'translate.admin.view.car')}</p>
                    <ScreenContentLayout classes="content content-column">
                        <ContentView  photosByColorsExterior={carDetailResponse.data.exterior_images_by_colors}
                                      colorsExterior={carDetailResponse.data.exterior_colors}
                                      photosByColorsInterior={carDetailResponse.data.interior_images_by_colors}
                                      colorsInterior={carDetailResponse.data.interior_colors}
                                      discList={discListArray}
                                      discDevider={discDevider}
                                      onlyRead={true}
                                      modeficationsId={modeficationsId}
                                      carId={carId}
                        />

                    </ScreenContentLayout>
                </>}
                </div>
            </div>
        </Box>
    );
};

export default ShowConfiguratorCarModal;
